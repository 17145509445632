<template>
  <div class="score-container">
    <header v-if="roleId!==12">
      <el-page-header @back="goBack" content="查看风险评估报告">
      </el-page-header>
    </header>
    <main>
      <div class="summary">
        <div class="summary-left">
          <div class="score">{{this.score.totalScore}}</div>
          <div class="extra">
            <p class="level" v-show="this.score.evaluateLevel==='A'" style="color:#67C23A">{{this.score.evaluateLevel}}</p>
            <p class="level" v-show="this.score.evaluateLevel==='B'" style="color:#E6A23C">{{this.score.evaluateLevel}}</p>
            <p class="level" v-show="this.score.evaluateLevel==='C'" style="color:#F56C6C">{{this.score.evaluateLevel}}</p>
            <p style="font-size:16px;color:#fff">较大火灾风险</p>
          </div>
        </div>
        <div class="summary-right">
          <el-button type="text" size="default" @click="ruleShow = true">评估规则</el-button>
        </div>
      </div>
      <div class="score-list">
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>建筑固有安全性(权重10%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.buildingSafe}}</p>
              <p>有效分数：{{this.score.buildingSafe*0.1}}</p>
            </div>
          </div>
        </div>
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>消防安全人员管理(权重24%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.firePeopleManage}}</p>
              <p>有效分数：{{this.score.firePeopleManage*0.24}}</p>
            </div>
          </div>
        </div>
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>消防设施设备运行(权重18%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.fireFacilityRun}}</p>
              <p>有效分数：{{this.score.fireFacilityRun*0.18}}</p>
            </div>
          </div>
        </div>
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>隐患排查及管理情况(权重8%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.dangerCheck }}</p>
              <p>有效分数：{{this.score.dangerCheck*0.08}}</p>
            </div>
          </div>
        </div>
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>单位基本信息(权重4%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.unitBasicInfo}}</p>
              <p>有效分数：{{this.score.unitBasicInfo*0.04}}</p>
            </div>
          </div>
        </div>
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>建筑的基础信息(权重14%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.buildingBasicInfo}}</p>
              <p>有效分数：{{this.score.buildingBasicInfo*0.14}}</p>
            </div>
          </div>
        </div>
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>消防组织机构及档案(权重12%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.unitFile}}</p>
              <p>有效分数：{{this.score.unitFile*0.12}}</p>
            </div>
          </div>
        </div>
        <div class="score-box">
          <div class="score-content">
            <div class="content-left">
              <p>“四色”风险现场评定(权重10%)<i class="el-icon-success" style="color:#67C23A"></i></p>
              <el-button type="text" size="default">查看<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
            <div class="content-right">
              <p>{{this.score.siteEvaluation}}</p>
              <p>有效分数：{{this.score.siteEvaluation*0.1}}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <el-dialog title="评估规则" :visible.sync="ruleShow" fullscreen class="rule-info">
      <div class="chartbox">
        <ul class="chartmenu">
          <li>一级项目</li>
          <li>二级项目</li>
          <li>评估内容</li>
          <li>评分标准</li>
          <li>评估分值</li>
          <li>分支权重</li>
        </ul>
        <table cellpadding="0" cellspacing="0" style="width:100%">
          <!-- 建筑固有安全性 -->
          <tbody style="width:100%">
            <tr rowspan="6" class="table-row">
              <th rowspan="5" class="table-name">建筑固有安全性</th>
              <td rowspan="1" class="table-first">消防验收情况</td>
              <td rowspan="1" class="table-all">消防验收情况</td>
              <td rowspan="1" class="table-score">建筑通过消防验收得40分，未通过验收不得分</td>
              <td rowspan="1" class="table-get">40</td>
              <td rowspan="1" class="table-weight">4%</td>
            </tr>
            <tr class="table-row">
              <td class="table-first">建筑结构</td>
              <td class="table-all">建筑结构</td>
              <td class="table-score">钢筋混泥土结构为20分，砖混结构为16分，钢结构为12分，砖木结构为8分，木结构为0分</td>
              <td class="table-get">20</td>
              <td class="table-weight">2%</td>
            </tr>
            <tr class="table-row">
              <td class="table-first">建筑高度</td>
              <td class="table-all">建筑高度</td>
              <td class="table-score">24米以下为20分，24-50米为16分，50-100米为12分，100米以上为8分</td>
              <td class="table-get">20</td>
              <td class="table-weight">2%</td>
            </tr>
            <tr class="table-row">
              <td class="table-first">建筑时间</td>
              <td class="table-all">建筑时间</td>
              <td class="table-score">建筑年限5年以内20分，建筑年限10年以内16分，建筑年限15年以内12分，建筑年限20年以内8分，建筑年限20年以上0分</td>
              <td class="table-get">20</td>
              <td class="table-weight">2%</td>
            </tr>
          </tbody>
          <!-- 消防安全人员管理 -->
          <tbody>
            <tr rowspan="4" class="table-row">
              <th rowspan="14" class="table-name">消防安全人员管理</th>
              <td rowspan="2" class="table-first">人员在岗情况</td>
              <td rowspan="1" class="table-all">微型消防站人员录入情况</td>
              <td rowspan="1" class="table-score">每录一个为2分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.8%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防控制室人员在岗情况</td>
              <td rowspan="1" class="table-score">在岗为10分，不在岗为0分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.8%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="5" class="table-first">人员演练培训情况</td>
              <td rowspan="1" class="table-all">灭火应急疏散演练录入</td>
              <td rowspan="1" class="table-score">每半年一次，按时间录入为10分，未录为0分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.8%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防安全培训录入</td>
              <td rowspan="1" class="table-score">每半年一次，按时间录入为10分，未录为0分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.8%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">员工“一警六员”消防技能实操实训</td>
              <td rowspan="1" class="table-score">每半年一次，总分10分，按人员录入，半年内录入员工培训数占总员工数100%得10分，半年内录入员工培训数占总员工数90%以上得8分，半年内录入员工培训数占总员工数80%以上得6分，半年内录入员工培训数占总员工数70%以上得4分，半年内录入员工培训数占总员工数60%以上得2分，半年内录入员工培训数占总员工数60%以下不得分）</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.8%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">微型消防站队员日常训练录入</td>
              <td rowspan="1" class="table-score">每月一次，按时间录入为5分，未录为0分）</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防控制室人员录入情况，持证上岗人数</td>
              <td rowspan="1" class="table-score">总分5分，每录一个为2分，录入3个5分(所在岗位选择消控室值班员，并且上传有效资质文件才算有效录入)</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="2" class="table-first">消防工作报告情况</td>
              <td rowspan="1" class="table-all">消防安全履职情况工作报告（年度）</td>
              <td rowspan="1" class="table-score">按时间录入为5分，未录为0分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">单位消防安全风险自我评估报告（季度）</td>
              <td rowspan="1" class="table-score">按时间录入为5分，未录为0分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="5" class="table-first">消防安全责任人和管理人工作履职情况</td>
              <td rowspan="1" class="table-all">消防安全责任人消防工作履职报告（半年度）</td>
              <td rowspan="1" class="table-score">按时间录入为5分，未录为0分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防安全管理人消防工作履职报告（半年度）</td>
              <td rowspan="1" class="table-score">按时间录入为5分，未录为0分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防安全管理人年度消防工作计划（当年）</td>
              <td rowspan="1" class="table-score">年度计划制定并审核通过后得5分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防安全责任人、管理人每季度带队开展防火检查（季度）</td>
              <td rowspan="1" class="table-score">5分，未带队开展防火检查则不得分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.9%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防安全管理人针对巡查员系统上报的火灾隐患，提出整改意见（月度）</td>
              <td rowspan="1" class="table-score">提出整改意见的得5分，消防安全责任人对整改意见进行审核确认后得10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.8%</td>
            </tr>
          </tbody>
          <!-- 消防设施设备运行 -->
          <tbody>
            <tr rowspan="5" class="table-row">
              <th rowspan="10" class="table-name">消防设施设备运行</th>
              <td rowspan="3" class="table-first">消防主机</td>
              <td rowspan="1" class="table-all">故障点占比</td>
              <td rowspan="1" class="table-score">无故障得15分，故障占比3％以内得12分，故障占比5％得9分，故障占比10％以内得6分，故障占比20％以内得3分，故障占比20％以上不得分</td>
              <td rowspan="1" class="table-get">15</td>
              <td rowspan="1" class="table-weight">3.6%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">屏蔽点占比</td>
              <td rowspan="1" class="table-score">无屏蔽点得10分，屏蔽点占比3％以内得8分，屏蔽点占比5％得5分，屏蔽点占比10％以内得3分，屏蔽点占比10％以上不得分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">2.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">火警点报警未处理数量</td>
              <td rowspan="1" class="table-score">15分，无火警点得15分，存在一个火警点未消除扣3分，扣完为止</td>
              <td rowspan="1" class="table-get">15</td>
              <td rowspan="1" class="table-weight">3.6%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">自动喷水灭火系统</td>
              <td rowspan="1" class="table-all">喷淋管道末端压力实时监测</td>
              <td rowspan="1" class="table-score">压力不足不得分，压力符合要求得20分</td>
              <td rowspan="1" class="table-get">20</td>
              <td rowspan="1" class="table-weight">4.8%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">消火栓灭火系统</td>
              <td rowspan="1" class="table-all">消火栓管道最不利点水压实时监测</td>
              <td rowspan="1" class="table-score">压力不足不得分，压力符合要求得20分</td>
              <td rowspan="1" class="table-get">20</td>
              <td rowspan="1" class="table-weight">4.8%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="2" class="table-first">消防水池水箱</td>
              <td rowspan="1" class="table-all">消防水箱液位实时监测</td>
              <td rowspan="1" class="table-score">液位不足不得分，液位符合要求得5分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防水池液位实时监测</td>
              <td rowspan="1" class="table-score">液位不足不得分，液位符合要求得5分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="3" class="table-first">消防设施维保情况</td>
              <td rowspan="1" class="table-all">单位录入维保公司情况</td>
              <td rowspan="1" class="table-score">录入信息的得2分，未录不得分</td>
              <td rowspan="1" class="table-get">2</td>
              <td rowspan="1" class="table-weight">0.48%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">单位月度维保记录，最近一次维保时间</td>
              <td rowspan="1" class="table-score">录入月度记录的得4分，未录不得分</td>
              <td rowspan="1" class="table-get">4</td>
              <td rowspan="1" class="table-weight">0.96%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">单位年度维护保养报告</td>
              <td rowspan="1" class="table-score">录入年度报告的得4分，未录不得分</td>
              <td rowspan="1" class="table-get">4</td>
              <td rowspan="1" class="table-weight">0.96%</td>
            </tr>
          </tbody>
          <!-- 隐患排查及管理情况 -->
          <tbody>
            <tr rowspan="4" class="table-row">
              <th rowspan="4" class="table-name">隐患排查及管理情况</th>
              <td rowspan="1" class="table-first">每月检查点位完成率</td>
              <td rowspan="1" class="table-all">每月检查点位完成率</td>
              <td rowspan="1" class="table-score">完成率100％得30分，完成率90％以上得25分，完成率80％以上得20分，完成率70％以上得15分，完成率60％以上得10分，完成率60％以下不得分</td>
              <td rowspan="1" class="table-get">30</td>
              <td rowspan="1" class="table-weight">2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">每日巡查点位完成率</td>
              <td rowspan="1" class="table-all">每日巡查点位完成率</td>
              <td rowspan="1" class="table-score">完成率100％得30分，完成率90％以上得25分，完成率80％以上得20分，完成率70％以上得15分，完成率60％以上得10分，完成率60％以下不得分</td>
              <td rowspan="1" class="table-get">30</td>
              <td rowspan="1" class="table-weight">2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">未整改的隐患存量</td>
              <td rowspan="1" class="table-all">未整改的隐患存量</td>
              <td rowspan="1" class="table-score">不存在存量的得30分，存在巡查检查点位发现问题未整改的，每一个扣5分，扣完为止</td>
              <td rowspan="1" class="table-get">30</td>
              <td rowspan="1" class="table-weight">2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">综合前期的隐患整改率</td>
              <td rowspan="1" class="table-all">综合前期的隐患整改率</td>
              <td rowspan="1" class="table-score">整改率100％得10分，整改率95％以上得8分，整改率90％以上得6分，整改率85％以上得4分，整改率80％以上得2分，整改率80％以下不得分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">2%</td>
            </tr>
          </tbody>
          <!-- 单位基本信息 -->
          <tbody>
            <tr rowspan="11" class="table-row">
              <th rowspan="11" class="table-name">单位基本信息</th>
              <td rowspan="1" class="table-first">单位名称</td>
              <td rowspan="1" class="table-all">单位名称</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位属性类别</td>
              <td rowspan="1" class="table-all">单位属性类别</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">监管责任单位</td>
              <td rowspan="1" class="table-all">监管责任单位</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位外观照片</td>
              <td rowspan="1" class="table-all">单位外观照片</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">5</td>
              <td rowspan="1" class="table-weight">0.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位总面积</td>
              <td rowspan="1" class="table-all">单位总面积</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位地址</td>
              <td rowspan="1" class="table-all">单位地址</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位所在楼层</td>
              <td rowspan="1" class="table-all">单位所在楼层</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">员工总人数</td>
              <td rowspan="1" class="table-all">员工总人数</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位类型</td>
              <td rowspan="1" class="table-all">单位类型</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位消防安全重点部位</td>
              <td rowspan="1" class="table-all">单位消防安全重点部位</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">单位平面图</td>
              <td rowspan="1" class="table-all">单位平面图</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">0.4%</td>
            </tr>
          </tbody>
          <!-- 建筑的基础信息 -->
          <tbody>
            <tr rowspan="10" class="table-row">
              <th rowspan="10" class="table-name">建筑的基础信息</th>
              <td rowspan="1" class="table-first">建筑地址</td>
              <td rowspan="1" class="table-all">建筑地址</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">疏散楼梯的数量</td>
              <td rowspan="1" class="table-all">疏散楼梯的数量</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">建筑产权</td>
              <td rowspan="1" class="table-all">建筑产权</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">建筑产权</td>
              <td rowspan="1" class="table-all">建筑产权</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">地上建筑面积</td>
              <td rowspan="1" class="table-all">地上建筑面积</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">地上层数</td>
              <td rowspan="1" class="table-all">地上层数</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">地下层数</td>
              <td rowspan="1" class="table-all">地下层数</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">地下建筑面积</td>
              <td rowspan="1" class="table-all">地下建筑面积</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">建筑按使用性质分类</td>
              <td rowspan="1" class="table-all">建筑按使用性质分类</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-first">安全出口的数量</td>
              <td rowspan="1" class="table-all">安全出口的数量</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.4%</td>
            </tr>
          </tbody>
          <!-- 消防组织机构及档案 -->
          <tbody>
            <tr rowspan="2" class="table-row">
              <th rowspan="8" class="table-name">消防组织机构及档案</th>
              <td rowspan="5" class="table-first">消防安全档案制度</td>
              <td rowspan="1" class="table-all">消防安全制度</td>
              <td rowspan="1" class="table-score">消防安全领导小组管理制度、消防安全教育培训制度、“一警六员”消防技能实操实训制度、防火巡查检查制度、火灾隐患整改制度、消防控制室值班管理制度、灭火应急疏散预案演练制度、消防设施定期检测维修制度、微型消防站建设管理制度、消防安全工作考评和奖惩制度、区域联勤联防工作制度、消防物联网远程监测技术应用制度。每少一项扣2分，扣完为止</td>
              <td rowspan="1" class="table-get">20</td>
              <td rowspan="1" class="table-weight">2.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">灭火和应急疏散预案</td>
              <td rowspan="1" class="table-score">未录扣20分</td>
              <td rowspan="1" class="table-get">20</td>
              <td rowspan="1" class="table-weight">2.4%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">微型消防站装备器材配备情况</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防工作奖惩情况</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">单位的消防行政许可文书</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="3" class="table-first">消防组织机构人员</td>
              <td rowspan="1" class="table-all">消防安全责任人及联系方式</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">消防安全管理人及联系方式</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
            <tr class="table-row">
              <td rowspan="1" class="table-all">专职消防管理人及联系方式</td>
              <td rowspan="1" class="table-score">未录扣10分</td>
              <td rowspan="1" class="table-get">10</td>
              <td rowspan="1" class="table-weight">1.2%</td>
            </tr>
          </tbody>
          <!-- “四色”风险现场评定 -->
          <tbody>
            <tr rowspan="2" class="table-row">
              <th rowspan="8" class="table-name">“四色”风险现场评定</th>
              <td rowspan="5" class="table-first">四色”风险现场评定</td>
              <td rowspan="1" class="table-all">“四色”风险现场评定</td>
              <td rowspan="1" class="table-score">现场打分</td>
              <td rowspan="1" class="table-get">100</td>
              <td rowspan="1" class="table-weight">10%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      score: {},
      ruleShow: false,
      roleId: ''
    }
  },
  created () {
    this.initData()
    this.roleId = parseInt(sessionStorage.getItem('roleId'))
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async initData () {
      const { data: result } = await this.$axios.get('/unitScore/list', { params: { unitId: this.$route.params.id } })
      if (result.code === 200) {
        this.score = result.data[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.score-container {
  width: 100%;
  height: 100%;
  background: rgba(22, 57, 98, 0.6);
  box-sizing: border-box;
  overflow-y: scroll;
  header {
    background-color: rgb(7, 63, 112);
    height: 60px;
    box-sizing: border-box;
    padding: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    ::v-deep .el-page-header__content {
      color: #fff;
    }
  }
  main {
    padding: 20px;
    box-sizing: border-box;
    .summary {
      height: 150px;
      border-radius: 20px;
      padding: 20px 40px;
      box-sizing: border-box;
      background-color: rgba(30, 74, 115, 0.9);
      display: flex;
      justify-content: space-between;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      .summary-left {
        display: flex;
        align-items: center;
        .score {
          font-size: 90px;
          color: #fff;
          font-family: Bahnschrift, sans-serif;
          vertical-align: middle;
        }
        .extra {
          margin-left: 20px;
          .level {
            font-size: 30px;
            font-family: Bahnschrift, sans-serif;
            text-align: left;
          }
        }
      }
    }
    .score-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      box-sizing: border-box;
      .score-box {
        width: 50%;
        height: 120px;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .score-content {
          width: 75%;
          height: 100%;
          border-radius: 20px;
          background-color: rgba(30, 74, 115, 0.9);
          display: flex;
          justify-content: space-between;
          padding: 20px;
          box-sizing: border-box;
          transition: all 0.3s linear;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          .content-left {
            text-align: left;
            p {
              color: #80ffff;
              i {
                margin-left: 10px;
              }
            }
          }
          .content-right {
            text-align: right;
            color: #80ffff;
            p:first-child {
              font-size: 48px;
              font-family: Bahnschrift, sans-serif;
            }
            p:last-child {
              font-size: 14px;
            }
          }
        }
        .score-content:hover {
          transform: translateY(-15px);
        }
      }
      .score-box:nth-child(7),
      .score-box:nth-child(8) {
        margin-bottom: 0;
      }
    }
  }
  .rule-info {
    ::v-deep .el-dialog__body {
      padding: 0;
      .chartbox {
        width: 100%;
        margin: 0 auto;
        min-height: 300px;
        .chartmenu {
          width: 100%;
          height: 50px;
          background-color: rgb(7, 63, 112);
          letter-spacing: -0.5em;
          font-size: 0;
          li {
            letter-spacing: normal;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            line-height: 50px;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
          }
          li:nth-of-type(1) {
            width: 15%;
            border-right: 1px solid rgb(7, 104, 159);
          }
          li:nth-of-type(2) {
            width: 15%;
            border-right: 1px solid rgb(7, 104, 159);
          }
          li:nth-of-type(3) {
            width: 20%;
            border-right: 1px solid rgb(7, 104, 159);
          }
          li:nth-of-type(4) {
            width: 40%;
            border-right: 1px solid rgb(7, 104, 159);
          }
          li:nth-of-type(5) {
            width: 5%;
            border-right: 1px solid rgb(7, 104, 159);
          }
          li:nth-of-type(6) {
            width: 5%;
          }
        }
        .table-row {
          width: 100%;
          color: #fff;
          .table-name {
            width: 15%;
            vertical-align: middle;
            font-size: 14px;
            color: #fff;
            border-left: 1px solid #c8e5fa;
            border-bottom: 1px solid #c8e5fa;
          }
          .table-first {
            width: 15%;
            text-align: center;
            vertical-align: middle;
            height: 44px;
            line-height: 44px;
            border-bottom: 1px solid #c8e5fa;
            border-left: 1px solid #c8e5fa;
            border-right: 1px solid #c8e5fa;
          }
          .table-all {
            width: 20%;
            text-align: center;
            vertical-align: middle;
            height: 44px;
            line-height: 44px;
            border-bottom: 1px solid #c8e5fa;
          }
          .table-score {
            width: 40%;
            text-align: left;
            vertical-align: middle;
            height: 44px;
            line-height: 44px;
            padding: 5px;
            box-sizing: border-box;
            border-bottom: 1px solid #c8e5fa;
            border-left: 1px solid #c8e5fa;
            border-right: 1px solid #c8e5fa;
          }
          .table-get {
            width: 5%;
            text-align: center;
            vertical-align: middle;
            border-bottom: 1px solid #c8e5fa;
            border-right: 1px solid #c8e5fa;
          }
          .table-weight {
            width: 5%;
            text-align: center;
            vertical-align: middle;
            border-bottom: 1px solid #c8e5fa;
            border-right: 1px solid #c8e5fa;
          }
        }
        .table-lastrow {
          height: 30px;
          line-height: 30px;
          background-color: #f6fafd;
          text-align: center;
          td:last-child {
            border-right: 1px solid #c8e5fa;
          }
          td:first-child {
            border-left: 1px solid #c8e5fa;
          }
        }
      }
    }
  }
}
</style>
